import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Inicio from './Inicio'
import Dashboard from './Dashboard'

import Clientes from './Clientes/CriarClientes'

import Login from './Login'

import CriarUsuarios from './Usuarios/CriarUsuarios'
import ListarUsuarios from './Usuarios/ListarUsuarios'
import AlterarUsuario from './Usuarios/AlterarUsuario'
import ListarUsuarioNome from './Usuarios/ListarUsuarioNome'

import Produtos from './Produtos/CadastroProdutos'
import ListarProdutos from './Produtos/ListarProdutos'

import Categorias from './Categorias/CriarCategorias'

export default function Rotas() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={ <Inicio /> } />
                <Route path='/Login' element={ <Login /> } />
                <Route path='/Dashboard' element={ <Dashboard /> } />
                <Route path='/CriarProdutos' element={ <Produtos /> } />
                <Route path='/ListarProdutos' element={ <ListarProdutos /> } />
                <Route path='/Clientes' element={ <Clientes /> } />
                <Route path='/CriarUsuarios' element={ <CriarUsuarios /> } />
                <Route path='/ListarUsuarios' element={ <ListarUsuarios /> } />
                <Route path='/ListarUsuarioNome' element={ <ListarUsuarioNome /> } />
                <Route path='/AlteraUsuario/:id' element={ <AlterarUsuario /> } />
                <Route path='/CriarCategorias' element={ <Categorias /> } />
            </Routes>
        </BrowserRouter>
    )
}