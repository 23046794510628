import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../Contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
//import { toast } from 'react-toastify'
import api from '../../Api/api'
import './listarprodutos.estilo.scss'


export default function ListarProdutos() {
    const navigation = useNavigate()
    const [produtos, setProdutos] = useState([''])
    const { isAutenthicated, loginToken } = useContext(AuthContext)
    const [loadProdutos, setLoadProdutos] = useState(false)

    const iToken = localStorage.getItem('@tksenac2023')
    const token = JSON.parse(iToken)
    loginToken()

    if (!token || !isAutenthicated) {
        navigation('/Login')
    }

    useEffect(() => {
        try {
            if (isAutenthicated) {
                async function listarProdutos() {
                    const resposta = await api.get('/ListarProdutos', {
                        headers: {
                            // eslint-disable-next-line no-useless-concat
                            Authorization: 'Bearer ' + `${token}`
                        }
                    })
                    setProdutos(resposta.data)
                    setLoadProdutos(true)
                }
                listarProdutos()
            }
        } catch (err) {

        }
        // eslint-disable-next-line
    }, [token])
    console.log(produtos)

    

    return (
        <div className='conteinerProdutos'>
            <div>
                <h1>Listar Produtos</h1>
            </div>
            <div className='mapProdutos'>
                {produtos.map((item) => {
                    return (
                        <article className='cardProdutos'>
                            <span><img src={`https://mss.eti.br:21093/files/${item.banner}`} alt="" /></span>
                            <p>{item.nome}</p>
                            <p>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.preco)}</p>
                        </article>
                    )
                })}
            </div>
        </div>
    )
}
