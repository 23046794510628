//import React, { } from 'react'
//import api from '../../Api/api'


export default function Categorias() {

    return (
        <div>
            <div>
                <h1>Pagina de Categorias</h1>
            </div>
            <div>
                <form>
                    <label>Nome da Categoria:</label>
                    <input 
                    type="text"

                     />
                     <button>Enviar</button>
                </form>
            </div>
        </div>
    )
}


