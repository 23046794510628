import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../Contexts/AuthContext'
import './dash.estilo.scss'
import { toast } from 'react-toastify'

export default function Dashboard() {
    const navigation = useNavigate()
    const { signOut, isAutenthicated, loginToken } = useContext(AuthContext)
    loginToken()

    if(!isAutenthicated){
        navigation('/Login')
        toast.error('Token Invalido',{
            toastId: 'toastId'
        })
    }

    function handleSair() {
        signOut()
        toast.info('Logout efetuado com sucesso')
        navigation('/')
    }

    return (
        <div className='conteinerDashboard'>
            <h1>Dashboard</h1>
            <div className='linksDashboard'>
                <div>
                    <h2><Link to='/ListarUsuarios'>Listar Usuários</Link></h2>
                    <h2><Link to='/ListarUsuarioNome'>Listar Usuário Por Nome</Link></h2>
                    <h2><Link to='/CriarUsuarios'>Criar Usuários</Link></h2>
                </div>
                <div>
                    <h2><Link to='/ListarProdutos'>Listar Produtos</Link></h2>
                    <h2><Link to='/CriarProdutos'>Criar Produtos</Link></h2>
                    <h2><Link to='/CriarCategorias'>Criar Categorias</Link></h2>
                </div>
            </div>
            <div className='buttonDashboard'>
                <button onClick={handleSair}>Sair</button>
            </div>
        </div>
    )
}