import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../../Api/api'
import { FiUpload } from 'react-icons/fi'
import { toast } from 'react-toastify'

import './cadastraproduto.estilo.scss'

export default function Produtos() {
    const navigation = useNavigate()

    const [nome, setNome] = useState('')
    const [fabricante, setFabricante] = useState('')
    const [quantidade, setQuantidade] = useState('')
    const [preco, setPreco] = useState('')
    const [fotoUrl, setFotoUrl] = useState('')
    const [image, setImage] = useState(null)

    const [valorCategorias, setValorCategorias] = useState('')
    const [categorias, setCategorias] = useState([''])

    const getToken = localStorage.getItem('@tksenac2023')
    const token = JSON.parse(getToken)

    useEffect(() => {
        async function lerCategorias() {
            if (!token) {
                navigation('/Login')
                toast.error('Erro de Autenticação')
            }
            const resposta = await api.get('/Listarcategorias')
            setCategorias(resposta.data)
        }
        lerCategorias()
        // eslint-disable-next-line
    }, [categorias])


    function handleFile(e) {
        if (!e.target.files) {
            return
        }
        const imagem = e.target.files[0]
        if (!imagem) {
            return
        }
        if (imagem.type === 'image/jpeg' || imagem.type === 'image/png') {
            setImage(imagem)
            setFotoUrl(URL.createObjectURL(e.target.files[0]))
        }
    }

    function handleCategorias(event) {
        setValorCategorias(event.target.value)
    }

    async function handleCadastrar(e) {
        try {
            e.preventDefault()
            const categoriaId = valorCategorias
            const data = new FormData()
            if (!nome || !fabricante || !quantidade || !preco || image === null || !categoriaId) {
                toast.warn('Existem Campos em Branco')
                return
            }
            data.append('nome', nome)
            data.append('fabricante', fabricante)
            data.append('quantidade', quantidade)
            data.append('preco', preco)
            data.append('categoriaId', categoriaId)
            data.append('file', image)

            await api.post('/CriarProdutos',
                data,
                {
                    headers: {
                        // eslint-disable-next-line no-useless-concat
                        Authorization: 'Bearer ' + `${token}`
                    }
                })
            toast.success('Produto Cadastrado com Sucesso')



        } catch (err) {
            toast.error(err.response.data.error)
        }

        setNome('')
        setFabricante('')
        setQuantidade('')
        setPreco('')
        setFotoUrl('')
        setImage(e.target.files = null)

    }

    return (
        <div className='conteinerCadastraProduto'>
            <h1>Produtos</h1>
            <form onSubmit={handleCadastrar}>
                <label>
                    <span>
                        <FiUpload size={45} color='#000000' />
                    </span>
                    <input
                        type='file'
                        accept='image/jpeg, image/png'
                        onChange={handleFile}
                    />

                    {fotoUrl && (
                        <img
                            src={fotoUrl}
                            alt="Foto Produto"
                            width={250}
                            height={250}
                        />
                    )}
                </label>
                <select value={valorCategorias} onChange={handleCategorias} >
                    <option>Selecione</option>
                    {categorias.map((item) => {
                        return (
                            <option
                                value={item.id}
                                key={item.id}>
                                {item.nome}
                            </option>
                        )
                    })}
                </select>
                <input
                    type="text"
                    placeholder='Nome'
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                />
                <input
                    type="text"
                    placeholder='Fabricante'
                    value={fabricante}
                    onChange={(e) => setFabricante(e.target.value)}
                />
                <input
                    type="text"
                    placeholder='Quantidade'
                    value={quantidade}
                    onChange={(e) => setQuantidade(e.target.value)}
                />
                <input
                    type="text"
                    placeholder='Preço'
                    value={preco}
                    onChange={(e) => setPreco(e.target.value)}
                />
                <button>Enviar</button>
            </form>
        </div>
    )
}