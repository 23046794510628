import Rotas from './routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AuthProvider } from './Contexts/AuthContext'

export default function App() {
  return (
    <AuthProvider>
      <div className='container-fluid'>
        <Rotas />
        <ToastContainer
          autoClose={5000}
          theme="colored"
        />
      </div>
    </AuthProvider>
  )
}
