import { createContext, useEffect, useState } from 'react'
import api from '../Api/api'

export const AuthContext = createContext()

export function AuthProvider({ children }) {
    
    const [user, setUser] = useState('')

    const iToken = localStorage.getItem('@tksenac2023')
    const token = JSON.parse(iToken)

    const isAutenthicated = !!user

    useEffect(() => {
        if (!token) {
            setUser('')
            return
        }
    }, [token, loginToken])

    async function loginToken() {
        const resposta = await api.get('/ListarUsuarioToken', {
            headers: {
                // eslint-disable-next-line no-useless-concat
                Authorization: 'Bearer ' + `${token}`
            }
        })
        if (resposta.data.dados) {
            setUser('')
        }
        //api.defaults.headers.common['Authorization'] = token
        setUser(resposta.data.id)
    }

    async function sigIn({ email, password }) {

        try {
            const resposta = await api.post('/AuthLogin', {
                email,
                password
            })
            setUser(resposta.data.id)
            return resposta
        } catch (err) {
            return (err)
        }
    }

    async function signOut() {
        localStorage.clear()
    }

    return (
        <AuthContext.Provider value={{ isAutenthicated, sigIn, signOut, loginToken }} >
            {children}
        </AuthContext.Provider>
    )
}

