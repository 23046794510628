import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FiTrash2, FiEdit } from 'react-icons/fi'
import { toast } from 'react-toastify'
import Spinner from 'react-bootstrap/Spinner'
import api from '../../Api/api'
import './listar.estilo.scss'

export default function ListarUsuarios() {
    const navigation = useNavigate()

    const [usuarios, setUsuarios] = useState([''])
    const [loading, setLoading] = useState(true)
    const [valido, setValido] = useState(false)

    const iToken = localStorage.getItem('@tksenac2023')
    const token = JSON.parse(iToken)

    if (!token) {
        navigation('/Login')
        toast.error('Token Invalido',{
            toastId: 'toastId'
        })
    }    

    useEffect(() => {
        async function verificaLogin() {
            const resposta = await api.get('/ListarUsuarioToken', {
                
                headers: {
                    // eslint-disable-next-line no-useless-concat
                    Authorization: 'Bearer ' + `${token}`
                }
            })
            if(resposta.data.dados){
                toast.error('Token Invalido',{
                    toastId: 'toastId'
                })
                navigation('/Login')
                return
            }
            if (!resposta.data.dados) {
                setValido(true)
            }
        }
        verificaLogin()

        async function loadUsuarios() {
            const resposta = await api.get('/ListarUsuarios', {
                headers: {
                    // eslint-disable-next-line no-useless-concat
                    Authorization: 'Bearer ' + `${token}`
                }
            })
            setUsuarios(resposta.data)
            setLoading(false)
        }
        loadUsuarios()       
        // eslint-disable-next-line
    }, [token, usuarios])

    async function excluirUsuarios(id) {
        if (!valido) {
            toast.error('Token Invalido',{
                toastId: 'toastId'
            })
            navigation('/Login')
            return
        }
        const resposta = await api.delete('/ApagarUsuarios', {
            data: {
                remover: id
            }
        })
        // localStorage.clear('@tksenac2023')
        toast.success(resposta.data.dados, {
            toastId: 'toastID'
        })
    }

    



    if (loading) {
        return (
            <div className='carregamento'>
                <div>
                    <h1>Carregando Lista</h1>
                </div>
                <div>
                    <Spinner className='Sp' animation='border' variant='warning' />
                </div>
            </div>
        )
    }

    return (
        <div className='conteinerListar'>
            <h1>Listar Usuários</h1>
            <div className='listarUsuarios'>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th>E-Mail</th>
                            <th>Criado</th>
                            <th>Alterado</th>
                            <th className='icones'>Editar</th>
                            <th className='icones'>Apagar</th>
                        </tr>
                        {usuarios.map((resultado) => {
                            return (
                                <tr>
                                    <td>{resultado.id}</td>
                                    <td>{resultado.nome}</td>
                                    <td>{resultado.email}</td>
                                    <td>{resultado.create_at}</td>
                                    <td>{resultado.update_at}</td>
                                    <td className='icones'><Link to={`/AlteraUsuario/${resultado.id}`}><FiEdit color='blue' size={20} /></Link></td>
                                    <td className='icones'><FiTrash2 color='red' size={20} onClick={() => excluirUsuarios(resultado.id)} /></td>
                                </tr>

                            )
                        })}
                    </thead>
                </table>
            </div>
            <button className='buttonInicioListar' onClick={() => navigation('/Dashboard')} >Dashboard</button>
        </div>
    )
}

